import { createTransparentColor } from '../create-transparent-color';
import type {
  CornerRadiusType,
  ElevationType,
  GeneralType,
  HeroColorType,
  LayoutColors,
  PrimitiveColorType,
  SignalColorType,
  SurfaceColorType,
  TextColorType,
} from './color-types';

const X3000BrandColor = {
  Elderberry: '#141529',

  // Primary Colors
  Primary: '#9500e6',

  // Alternate Colors
  Alternate: '#00C6FA',
  AlternateLight: '#31D6FF',

  // Accent Colors
  Purple: '#9500e6',
  Green: '#16F87F',
  GreenLight: '#3EF894',
  Red: '#FB3640',

  // Gradients
  GradientStart: '#ff00ff',
  GradientEnd: '#00ffff',
  GradientPurpleStart: '#A700FF',
  GradientPurpleEnd: '#60008B',

  DeepBlue: '#0c0d1b',

  Black: '#02020a',
  Darker: '#101023',
  Dark: '#030518',
  Light: '#F9F0FC',
  Neutral: '#7f7c91',
  White: '#ffffff',
};

const Primitive: PrimitiveColorType = {
  Primary: X3000BrandColor.Purple,
  PrimaryTint: X3000BrandColor.GradientPurpleStart,
  PrimaryContrast: X3000BrandColor.White,

  Secondary: X3000BrandColor.Alternate,
  SecondaryTint: X3000BrandColor.AlternateLight,
  SecondaryContrast: X3000BrandColor.White,

  Accent: X3000BrandColor.Green,
  AccentTint: X3000BrandColor.GreenLight,
  AccentContrast: X3000BrandColor.Black,

  Gradient: `
  linear-gradient(0deg, ${X3000BrandColor.GradientPurpleEnd}ee, ${X3000BrandColor.GradientPurpleStart}00) border-box,
  linear-gradient(-90deg, ${X3000BrandColor.GradientEnd} 1%, ${X3000BrandColor.GradientEnd} 10%, ${X3000BrandColor.GradientStart} 97%, ${X3000BrandColor.GradientStart} 99%) border-box
  `,
  GradientTint: `
  linear-gradient(0deg, ${X3000BrandColor.GradientPurpleEnd}ff 10%, ${X3000BrandColor.GradientPurpleStart}00) border-box,
  linear-gradient(-90deg, ${X3000BrandColor.GradientEnd} 1%, ${X3000BrandColor.AlternateLight} 10%, ${X3000BrandColor.GradientStart} 97%, ${X3000BrandColor.GradientStart} 99%) border-box
  `,
  GradientContrast: X3000BrandColor.White,
};

const TextColors: TextColorType = {
  HeadingText: X3000BrandColor.White,
  BodyText: X3000BrandColor.Light,
  MutedText: '#b4a5bb',
  HighlightedText: X3000BrandColor.White,
  LinkText: X3000BrandColor.Green,
  ErrorText: X3000BrandColor.Red,
  DisabledText: X3000BrandColor.Neutral,
};

const Surface: SurfaceColorType = {
  Base: {
    Background: X3000BrandColor.DeepBlue,
    Foreground: X3000BrandColor.White,
    Dimmed: createTransparentColor(X3000BrandColor.Light, 0.25),
  },
  Nested: {
    Background: X3000BrandColor.Black,
    Foreground: X3000BrandColor.Light,
    Dimmed: createTransparentColor(X3000BrandColor.Neutral, 0.5),
  },
  Disabled: {
    Background: X3000BrandColor.Neutral,
    Foreground: X3000BrandColor.Dark + '80',
    Dimmed: createTransparentColor(X3000BrandColor.Dark, 0.15),
  },
  Floating: {
    Background: X3000BrandColor.Dark,
    Foreground: X3000BrandColor.White,
    Dimmed: createTransparentColor(X3000BrandColor.Light, 0.15),
  },
};

const Signal: SignalColorType = {
  Success: X3000BrandColor.Green,
  SuccessContrast: X3000BrandColor.Darker,

  Info: X3000BrandColor.Green,
  InfoContrast: X3000BrandColor.Darker,

  Attention: X3000BrandColor.Purple,
  AttentionContrast: X3000BrandColor.White,

  Danger: X3000BrandColor.Red,
  DangerContrast: X3000BrandColor.White,
};

const CornerRadius: CornerRadiusType = {
  Small: '4px',
  Base: '8px',
  Large: '12px',
};

const Elevation: ElevationType = {
  Level1: '0 1px 2px 0 var(--shadow-color, rgba(0, 0, 0, 0.2))',
  Level2: `0 4px 6px 0 var(--shadow-color, ${createTransparentColor(X3000BrandColor.Black, 0.2)})`,
  Level3: '0 8px 12px 0 var(--shadow-color, rgba(0, 0, 0, 0.15))',
};

const General: GeneralType = {
  Bonus: X3000BrandColor.Purple,
  BonusContrast: X3000BrandColor.White,
};

const Layout: LayoutColors = {
  Background: X3000BrandColor.Elderberry,
  BrandBorder: X3000BrandColor.Purple,
  BrowserTheme: X3000BrandColor.Elderberry,
  LoadingColor: X3000BrandColor.Purple,
  Overlay: X3000BrandColor.Elderberry + 'E6',
  Sidebar: {
    Background: X3000BrandColor.Dark,
    Foreground: X3000BrandColor.White,
  },
};

const Hero: HeroColorType = {
  Heading: X3000BrandColor.White,
  Text: X3000BrandColor.White,
  TextHighlight: X3000BrandColor.Green,

  Button: {
    Solid: Primitive.Primary,
    Rest: Primitive.Gradient,
    Hover: Primitive.GradientTint,
    Active: Primitive.GradientTint,
    Text: Primitive.GradientContrast,
  },
};

export const Color = {
  ...X3000BrandColor,
  ...TextColors,
  Primitive,
  Surface,
  Signal,
  Elevation,
  CornerRadius,
  General,
  Layout,
  Hero,

  Background: X3000BrandColor.Elderberry,
  CardBackground: X3000BrandColor.DeepBlue,

  Aux: X3000BrandColor.Red,

  Interactive: X3000BrandColor.Green,
  InteractiveHover: createTransparentColor(X3000BrandColor.Green, 0.1),
  FocusHighlight: createTransparentColor(X3000BrandColor.Green, 0.5),

  // Shadows, highlight and transparency
  Shadow: createTransparentColor(X3000BrandColor.Dark, 0.35),
  ShadowInset: createTransparentColor(X3000BrandColor.Light, 0.2),
  DarkTransparent50: createTransparentColor(X3000BrandColor.Dark, 0.5),
  BlackTransparent90: createTransparentColor(X3000BrandColor.Black, 0.9),
  PurpleTransparent20: createTransparentColor(X3000BrandColor.Purple, 0.2),
  LightTransparent20: createTransparentColor(X3000BrandColor.Light, 0.2),
  LightTransparent50: createTransparentColor(X3000BrandColor.Light, 0.5),

  // AdaptiveBackground is used for gradients is some brands.
  // Can be used like `linear-gradient(90deg, ${ColorStart}, ${ColorEnd})`,
  AdaptiveBackground: `
  linear-gradient(0deg, ${X3000BrandColor.GradientPurpleEnd}ee, ${X3000BrandColor.GradientPurpleStart}00) border-box,
  linear-gradient(-90deg, ${X3000BrandColor.GradientEnd} 1%, ${X3000BrandColor.GradientEnd} 10%, ${X3000BrandColor.GradientStart} 97%, ${X3000BrandColor.GradientStart} 99%) border-box
  `,

  // Theme color for mobile browsers
  BrowserTheme: X3000BrandColor.Elderberry,
};
