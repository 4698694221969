/**
 * @generated SignedSource<<28d5aa5101ee06cbc3e267a09655afd1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderSearchBar_data$data = {
  readonly site: {
    readonly menu: {
      readonly search: {
        readonly gameListId: string | null;
      };
    } | null;
  };
  readonly " $fragmentType": "HeaderSearchBar_data";
};
export type HeaderSearchBar_data$key = {
  readonly " $data"?: HeaderSearchBar_data$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderSearchBar_data">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderSearchBar_data",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Site",
      "kind": "LinkedField",
      "name": "site",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Menu",
          "kind": "LinkedField",
          "name": "menu",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "MenuSearch",
              "kind": "LinkedField",
              "name": "search",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "gameListId",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "6c2aa708cbfd59a9d247c4cd73b54038";

export default node;
