import styled from '@emotion/styled';
import { Link } from '@pafcloud/base-components';
import { BaseFont, Breakpoint, Color, deviceWithHover } from '@pafcloud/style';

export const HeaderMenuLink = styled(Link)({
  position: 'relative',
  display: 'flex',
  justifyContent: 'center',
  fontFamily: BaseFont.BrandFont,
  color: Color.BodyText,
  outline: 'none',

  '& + &': {
    '::after': {
      content: '""',
      position: 'absolute',
      left: 'calc(-1rem - 4px)',
      top: '50%',
      height: '1rem',
      background: Color.Primitive.Primary,
      width: 3,
      transform: 'skew(-20deg) translateY(-50%)',
    },
  },

  [Breakpoint.LaptopOrLarger]: {
    '& + &': {
      '::after': {
        display: 'none',
      },
    },
  },

  // Underline hover effect
  '::before': {
    content: '""',
    position: 'absolute',
    bottom: -4,
    borderRadius: 4,
    height: 2,
    width: 0,
    background: 'currentColor',
    transition: 'width 0.2s ease-in-out',
  },

  // Current link
  '&[aria-current="true"]::before': {
    width: 'calc(100% + 2px)',
  },

  [deviceWithHover]: {
    '&:focus-visible::before': {
      width: 'calc(100% + 2px)',
      background: Color.Primitive.Accent,
    },
    '&:hover::before': {
      width: 'calc(100% + 2px)',
    },
  },
});
