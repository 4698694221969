/**
 * @generated SignedSource<<6b3887326490e59df79730587d2ec532>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HeaderContentX3000_content$data = {
  readonly " $fragmentSpreads": FragmentRefs<"HeaderMenu_content" | "HeaderSearchBar_data" | "ProfileMenu_data">;
  readonly " $fragmentType": "HeaderContentX3000_content";
};
export type HeaderContentX3000_content$key = {
  readonly " $data"?: HeaderContentX3000_content$data;
  readonly " $fragmentSpreads": FragmentRefs<"HeaderContentX3000_content">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HeaderContentX3000_content",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderMenu_content"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HeaderSearchBar_data"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ProfileMenu_data"
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "64b2998b95174b78f254c8e0c3aec748";

export default node;
