import { Color } from '@pafcloud/style';
import { Colors as Colors_default } from './colors.default';

export const Colors = {
  ...Colors_default,

  BackgroundBlur: Color.Surface.Floating.Background + 'A6',
  Background: Color.Surface.Floating.Background + 'cc',

  Icon: Color.BodyText,
  IconFocus: Color.HeadingText,
};
