import { X3000Color } from '@pafcloud/style';

export const Colors = {
  Text: X3000Color.White,
  MessageSeenText: X3000Color.Neutral,
  PaginationText: X3000Color.BodyText,

  BorderColor: X3000Color.Background,

  Highlight: X3000Color.PurpleTransparent20,
  HighlightBorder: X3000Color.Purple,
};
